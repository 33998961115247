$baseurl: "http://127.0.0.1:4000";
// $baseurl: "/jekyll";
$main-width: 1134px;
$max-width: 1134px;
$md-break: 978px;


$pd_top: 50px;
$pd_bot: 50px;

$font-color: linear-gradient(black, #000036);
$black: #020202;
$footer-black: rgb(33, 40, 46);
$main-color: #59bdd5;
$blue: #59bdd5;
$blue-gradient: linear-gradient(to left, #00b9e3, #59bdd5 40%);
$blue-gradient-font: linear-gradient(black, #000036);
// #008752
$green: rgba(0, 135, 82, 1);
$green-gradient: linear-gradient(to left, #01653d, #008752 70%);
$link-gradient-font: linear-gradient(#600a8c, #6e00d1);

*,
*:after,
*:before {
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	margin-bottom: 0;
	// border: 1px solid cyan;
}

*> :first-child {
	margin-top: 0;
}

html {
	font-size: 100%;
	color: linear-gradient(black, #000036);

	@media (min-width: $md-break) {
		font-size: 125%;
	}
}

body {
	margin: 0;
}

.titleP {
	font-family: "Oswald";
	font-size: 3em;
	font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Oswald";
}

/* clear */
.clear:before,
.clear:after {
	content: '';
	display: table;
}

.titleP,
h1,
h2,
h3,
p,
ul {
	padding: 0 16px;

	@media (min-width: $md-break) {
		padding: 0;
	}
}

.clear:after {
	clear: both;
}

.clear {
	*zoom: 1;
}

img {
	max-width: 100%;
	vertical-align: bottom;
	margin-bottom: 0;
}

a {
	color: $blue-gradient-font;
	text-decoration: none;
}

a:hover {
	color: $link-gradient-font;
}

a:focus {
	outline: 0;
}

a:hover,
a:active {
	outline: 0;
}

input:focus {
	outline: 0;
	border: 1px solid #04a4cc;
}


/*------------------------------------*\
    MISC
\*------------------------------------*/

::selection {
	background: #04a4cc;
	color: #fff;
	text-shadow: none;
}

::-webkit-selection {
	background: #04a4cc;
	color: #fff;
	text-shadow: none;
}

::-moz-selection {
	background: #04a4cc;
	color: #fff;
	text-shadow: none;
}

// CLEAR ENDS
// CLEAR ENDS
// CLEAR ENDS


header {
	padding: 1em 0;
	background: $black;
	/* includes mobile and desktop nav */
}

#nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
}

// margin for the button on mobile
.sidebarBtn {
	margin-right: 16px;
	display: flex;

	p {
		color: white;
		margin: auto 5px auto 0;
		padding: 4px 0 0 0;
	}

}

.sidebarBtnStyle,
.siteLogo {
	margin: 0 .5em;
}

#navigation {
	display: none;

	position: absolute;
	top: 0;
	left: 0;

	height: 100%;
	width: 100%;
}

#navigation.visible {
	display: flex;
	flex-direction: column;
	transition: 0.3s ease-in-out;
}

#navigation.hide {
	display: none;
}

#menuClose {
	cursor: pointer;
	text-align: center;

	margin: 2em;
}

#sidebarBtn {
	cursor: pointer;
}

.navStyle {
	display: inline-block;
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: scroll;


	background: $blue-gradient;
	z-index: 9999;

	a {
		color: $blue-gradient-font;
		text-decoration: none;
		line-height: 35px;
		font-size: 1.4rem;
		padding-left: 2em;
	}
}

#navigation a,
#navigation p {
	color: $black;
}


// flex shink 0 prevents boxes overlapping in safari
.servicesMenuContainer {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	font-size: 1.2rem;
	padding-top: .5em;
	padding-bottom: .5em;
	padding-left: 2.3em;

	:first-of-type {
		text-decoration: underline;
		font-weight: bold;
		margin-bottom: 8px;
	}
}

/* Nav Mobile END ------- */
/* ------------------------ */



.home {

	.post-list-heading,
	.post-list,
	.rss-subscribe {
		display: none;
	}
}


.postsWrapper {
	display: grid;
	grid-template-columns: 16px 50em 16px;
	margin: 0 auto;
	justify-content: center;

	.postsGrid {
		grid-column: 2;
	}
}

// Layout

.container {
	display: flex;
	width: 100%;
	flex-direction: column;
}


.row {
	padding: 3em 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;

	max-width: $max-width;

	margin: 0 auto;

}

.buttonCenter {
	display: flex;
	justify-content: center;
	margin: 2em;
}

.placeCenter {
	place-self: center;
}

.footerBlack {
	background: $footer-black;
}

.blue {
	background: $blue-gradient;
	color: $blue-gradient-font;
}

.green {
	background: $green-gradient;
}

.white {
	color: white;
}

.whiteLink {
	color: white;
	text-decoration: underline;
}

.black {
	color: $blue-gradient-font;
}

.padding {
	padding-top: $pd_top;
	padding-bottom: $pd_bot;
}

.flexColumnCenter {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.justify {
	justify-content: center;
}

.align {
	align-content: center;
}

.placeSelfCenter {
	place-self: center
}

.center {
	text-align: center;
}


// Covid
.anncouncements {
	background: $blue-gradient;
	color: $blue-gradient-font;
	text-align: center;
	padding: 4px 0;
}

.one {
	display: grid;
	grid-template-columns: 16px auto 16px;
	justify-content: center;
	row-gap: 1em;

	.a {
		grid-column: 2;
	}

	@media (min-width: $md-break) {
		grid-template-columns: 1fr;

		.a {
			grid-column: 1;
		}
	}
}

.oneThird {
	display: grid;
	width: 100%;
	grid-template-columns: 16px 1fr 16px;
	justify-content: center;

	.a {
		grid-column: 2;
	}

	.b {
		grid-column: 2;
	}

	@media (min-width: $md-break) {
		grid-template-columns: 3fr 1fr;

		.a {
			grid-column: 1;
		}

		.b {
			grid-column: 2;
		}
	}
}

.threeOne {
	display: grid;
	grid-template-columns: 16px 1fr 16px;
	row-gap: 2em;
	margin: 0 auto;

	.a,
	.b {
		width: 100%;
		display: flex;
		flex-direction: column;
		// place-items: center;
	}

	.a,
	.b {

		h1,
		h2,
		h3,
		p {
			max-width: 95%;
		}
	}

	.a {
		grid-column: 2;
	}

	.b {
		grid-column: 2;
	}

	.imgs {
		flex-direction: column;
		justify-content: center;
		align-content: center;
	}

	.threeOne__imgs {
		margin-top: 3em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
	}

	@media (min-width: $md-break) {
		grid-template-columns: 2fr 1fr;
		gap: 2em;
		row-gap: 0;

		.a {
			grid-column: 1;
		}

		.b {
			grid-column: 2;
		}

		.imgs {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-content: center;
		}
	}
}

.threeOneReverse {
	display: grid;
	grid-template-columns: 16px 1fr 16px;
	row-gap: 2em;
	margin: 0 auto;

	.a,
	.b {
		width: 100%;
		display: flex;
		flex-direction: column;
		place-items: center;
	}

	.a,
	.b {

		h1,
		h2,
		h3,
		p {
			max-width: 95%;
		}
	}

	.a {
		grid-column: 2;
	}

	.b {
		grid-column: 2;
	}

	.imgs {
		flex-direction: column;
		justify-content: center;
		align-content: center;
	}

	.threeOne__imgs {
		margin-top: 3em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
	}

	@media (min-width: $md-break) {
		grid-template-columns: 1fr 2fr;
		gap: 2em;

		.a {
			grid-column: 2;
			grid-row: 1;
		}

		.b {
			grid-column: 1;
			grid-row: 1;
		}

		.imgs {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-content: center;
		}
	}
}

.twoColumn {
	display: grid;
	grid-template-columns: 16px 1fr 16px;
	row-gap: 2em;
	margin: 0 auto;

	.twoColumn__a,
	.twoColumn__b {
		width: 100%;
		display: flex;
		flex-direction: column;
		place-items: center;
	}

	.twoColumn__a,
	.twoColumn__b {

		h1,
		h2,
		h3,
		p {
			max-width: 95%;
		}
	}

	.twoColumn__a {
		grid-column: 2;
	}

	.twoColumn__b {
		grid-column: 2;
	}

	.imgs {
		flex-direction: column;
		justify-content: center;
		align-content: center;
	}

	.twoColumn__imgs {
		margin-top: 3em;
		display: flex;
		// flex-direction: column;
		// justify-content: center;
		align-content: center;
	}

	@media (min-width: $md-break) {
		grid-template-columns: 1fr 1fr;
		gap: 2em;
		row-gap: 0;

		.twoColumn__a {
			grid-column: 1;
		}

		.twoColumn__b {
			grid-column: 2;
		}

		.twoColumn__imgs {
			display: flex;
			flex-direction: row;
		}
	}
}

// Big Left Small Right
// --------------------
.twoColumnV2 {
	display: grid;
	grid-template-columns: 16px 1fr 16px;
	row-gap: 2em;
	margin: 0 auto;

	.twoColumn__a,
	.twoColumn__b {
		width: 100%;
		display: flex;
		flex-direction: column;
		place-items: center;
	}

	.twoColumn__a,
	.twoColumn__b {

		h1,
		h2,
		h3,
		p {
			max-width: 95%;
		}
	}

	.twoColumn__a {
		grid-column: 2;
	}

	.twoColumn__b {
		grid-column: 2;
	}

	.imgs {
		flex-direction: column;
	}

	.twoColumn__imgs {
		margin-top: 1em;
		display: flex;
		flex-direction: column;
		// justify-content: center;
		// align-content: center;
		width: 100%;

		.hardieIMG {
			margin: 0 auto;
		}

		.homestarsGrid {
			display: flex;
			justify-content: space-around;
			align-content: center;
		}
	}

	.centerNotCenter {
		text-align: center;
	}

	@media (min-width: $md-break) {
		grid-template-columns: 2fr 1fr;
		gap: 2em;
		row-gap: 0;

		.twoColumn__a {
			grid-column: 1;
		}

		.twoColumn__b {
			grid-column: 2;
		}

		.twoColumn__imgs {
			margin-top: 0;
			display: flex;
			flex-direction: column;
			align-content: center;
			width: 100%;
		}
	}

	.centerNotCenter {
		text-align: left;
	}
}

.threeColumn {
	display: grid;
	grid-template-columns: 16px 1fr 16px;
	// justify-content: center;


	.gridA,
	.gridB,
	.gridC {
		display: flex;
		place-self: center;

		grid-column: 2;
	}

	@media (min-width: $md-break) {
		grid-template-columns: 1fr 1fr 1fr;

		.gridA {
			grid-column: 1;
		}

		.gridB {
			grid-column: 2;
		}

		.gridC {
			grid-column: 3;
		}
	}



	.gridC {
		color: white;
		display: grid;

		.table {
			display: flex;
			flex-wrap: nowrap;

			.tableA,
			.tableB {
				margin: 1em;
			}
		}
	}
}

// Used on Siding Installation Page

.fourGrid {
	display: grid;
	grid-template-columns: 16px auto 16px;

	.a,
	.b,
	.c,
	.d,
	.e {
		grid-column: 2;
	}

	@media (min-width: $md-break) {
		grid-template-columns: 1fr 1fr;
		gap: 2em;

		.a {
			grid-column: 1 / 3;
			grid-row: 1;
		}

		.b,
		.c {
			grid-row: 2;
		}

		.d,
		.e {
			grid-row: 3;
		}

		.b {
			grid-column: 1;
		}

		.c {
			grid-column: 2;
		}

		.d {
			grid-column: 1;
		}

		.e {
			grid-column: 2;
		}
	}
}

// END used on siding installation page

// USED ON SIDING REPAIR PAGE

.sidingRepair_flexA {
	margin-top: 1em;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (min-width: $md-break) {
		flex-direction: row;
		flex-basis: 1;
	}
}

// END SIDING REPAIR PAGE

// Buttons

button {
	cursor: pointer;
	color: white;
	background-color: $black;
	padding: 20px 16px;
	margin-top: 1em;
	border: none;
	font-size: 18px;
	text-transform: uppercase;
	margin-top: 1em auto;

	:hover {
		color: $main-color;
	}
}

.secondButton {
	margin: 0;
}

// If button is in a div like Homepage

.buttonDiv {
	display: flex;
	flex-direction: column;

	button {
		margin: 1em auto;
		max-width: 200px;
	}
}

.backgroundFixed {
	height: auto;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	justify-content: center;

	h2,
	p {
		color: white;
	}
}

.homeHeroTaiga,
.homeHeroLux,
.homeHeroLuxTwo {
	min-height: 50vh;
}

.homeHeroTaiga {
	background: url('/assets/images/taiga/lpc_2_taiga_siding_1920.jpg') center;
}

.homeHeroLux {
	background: url('/assets/images/lux/lux_Background_desktop-1.jpg') center;
}

.homeHeroLuxTwo {
	background: url('/assets/images/lux/backgrounds/luxheaderv2.jpg') center;
	color: white;
}




// nav{
//   position: fixed;
//   width: 100%;
//   float: right;
// }
// 	nav ul {
//     	list-style: none;
//     	position: relative;
//     	float: right;
//     	margin-right: 16px;
//     	display: inline-table;
//   	}
// 	nav ul li {
//     	float: left;
//       	-webkit-transition: all .2s ease-in-out;
//       	-moz-transition: all .2s ease-in-out;
//       	transition: all .2s ease-in-out;
//     	}
//     nav ul li:hover{background: rgba(0,0,0,.15);}
//     nav ul li:hover > ul{display: block;}
//     nav ul li {
//     	  float: left;
//       	-webkit-transition: all .2s ease-in-out;
//       	-moz-transition: all .2s ease-in-out;
//       	transition: all .2s ease-in-out;
//     }
//     nav ul li a {
//         display: block; 
//         padding: 30px 20px;
//         color: #222; 
//         font-size: .9em;
//         letter-spacing: 1px;
//         text-decoration: none;
//         text-transform: uppercase;
//     }
//     nav ul ul{
//         display: none;
//         background: #fff;
//         position: absolute; 
//         top: 100%;
//         box-shadow: -3px 3px 10px -2px rgba(0,0,0,.1);
//         border: 1px solid rgba(0,0,0,.1);
// 	}
//     nav ul ul li{float: none; position: relative;}
//     nav ul ul li a {
//         padding: 15px 30px; 
//         border-bottom: 1px solid rgba(0,0,0,.05);
//         }
//     nav ul ul ul {
//         position: absolute; 
//         left: 100%; 
//         top:0;
//         }	


// POSTS

.postsWrapper {
	display: flex;
	max-width: $main-width;
	margin: 3em auto;
}

.postsGrid {
	display: grid;
	grid-template-columns: 16px auto 16px;

	.gridA,
	.dateWrapper {
		grid-column: 2;
	}

	.dateWrapper {
		margin-top: 2em;
	}
}

.postsFlex {
	display: grid;
	gap: 1em;
	grid-template-columns: 16px 1fr 16px;

	.postsFlexItem {
		grid-column: 2;
		padding-bottom: 1em;
		margin-bottom: 1em;
		border-bottom: 4px solid $blue;
		border-radius: 16px;
	}

	@media (min-width: $md-break) {
		grid-template-columns: 1fr 800px 1fr;
	}
}

// PORTFOLIO

.portfolioWrapper {
	display: flex;
	max-width: $main-width;
	margin: 3em auto;

	.a {
		display: flex;
		flex-direction: column;
		margin: 0 16px;
	}
}

.portfolioPage {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 2em;

	@media (min-width: $md-break) {
		grid-template-columns: 1fr 1fr;

		.a {
			grid-column: 2;
			grid-row: 1;
		}

		.b {
			grid-column: 1;
			grid-row: 1;
		}
	}
}


.portfolioFlex {
	display: grid;
	gap: 1em;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

	.portfolioFlexItem {
		display: grid;
		grid-template-columns: minmax(400px, 1fr);
	}
}

.flexme {
	display: flex;
	justify-content: center;
}

// GALLERY

.galleryRow {
	padding: 3em 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;

	margin: 1em 0;

	// max-width: $max-width;

	margin: 0 auto;
}

.luxGalleryPage {
	h1 {
		color: white;
		text-transform: uppercase;
	}

	.linkColor {
		color: white;
		text-decoration: underline;
	}
}

.luxGallery_autoGridRow {
	margin: 1em;

	.column {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		gap: 1em;
	}
}